<template>
  <div>
    <PageBar Image="img/article_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="container" v-if="Document">
        <h1>
          {{ Document.Title
          }}<span class="subTitle" v-if="Document.SubTitle">{{
            Document.SubTitle
          }}</span>
        </h1>


<!--------------------- 習題系統前台 ----------------->
        <!-- <div class="pageBox">
          <div class="qustionPage">
            <div class="testerInfo">
              <p class="name">姓名: XXX</p>
              <p class="subject">報檢職類級別: 電腦軟體應用(丙級)-第1題組</p>
              <p class="testID">准考證號碼: 98383-39920</p>
            </div>
            <div class="remainTime">
              剩餘時間:
              <span class="minute">50</span> 分
              <span class="second">50</span> 秒	
            </div>


            <ol class="questionList">
              <li class="questionList-item">
                <h3>運動設施可能建置於校園、公園或運動園區(亦或是獨立場館)；管理機關為體育局(處、場)者依促參法歸屬為哪個類組之公共建設？</h3>
                <div class="answers">
                  <label for="a1-1">
                    <input type="radio" id="a1-1" name="a1">
                    （1）	文教設施 
                  </label>
                  <label for="a1-2">
                    <input type="radio" id="a1-2" name="a1">
                    （2）	休閒設施 
                  </label>
                  <label for="a1-3">
                    <input type="radio" id="a1-3" name="a1">
                    （3）	運動設施 
                  </label>
                  <label for="a1-4">
                    <input type="radio" id="a1-4" name="a1">
                    （4）	公共設施 
                  </label>
                </div>
              </li>
              <li class="questionList-item">
                <h3>運動設施管理機關為學校者依促參法歸屬於文教設施類組；下列何種運動設施不適用促參法招商？</h3>
                <div class="answers">
                  <label for="a2-1">
                    <input type="radio" id="a2-1" name="a2">
                    （1）	游泳池 
                  </label>
                  <label for="a2-2">
                    <input type="radio" id="a2-2" name="a2">
                    （2）	體育館 
                  </label>
                  <label for="a2-3">
                    <input type="radio" id="a2-3" name="a2">
                    （3）	高爾夫練習場 
                  </label>
                  <label for="a2-4">
                    <input type="radio" id="a2-4" name="a2">
                    （4）	以上皆非
                  </label>
                </div>
              </li>
              <li class="questionList-item">
                <h3>下列何者非學校運動場館未能積極對外開放之原因？</h3>
                <div class="answers">
                  <label for="a3-1">
                    <input type="radio" id="a3-1" name="a3">
                    （1）	營運經費與人力不足
                  </label>
                  <label for="a3-2">
                    <input type="radio" id="a3-2" name="a3">
                    （2）	房屋稅與地價稅稅金太高，學校付不起
                  </label>
                  <label for="a3-3">
                    <input type="radio" id="a3-3" name="a3">
                    （3）	缺乏績效及回饋制度
                  </label>
                  <label for="a3-4">
                    <input type="radio" id="a3-4" name="a3">
                    （4）	學校無法開立發票，會有逃漏稅之疑慮
                  </label>
                </div>
              </li>
              <li class="questionList-item">
                <h3>下列何者非我國政府硬性要求各「國民運動中心」須配置之專業證照人才？</h3>
                <div class="answers">
                  <label for="a4-1">
                    <input type="radio" id="a4-1" name="a4">
                    （1）	游泳池水質管理員
                  </label>
                  <label for="a4-2">
                    <input type="radio" id="a4-2" name="a4">
                    （2）	國民體適能指導員
                  </label>
                  <label for="a4-3">
                    <input type="radio" id="a4-3" name="a4">
                    （3）	運動傷害防護員
                  </label>
                  <label for="a4-4">
                    <input type="radio" id="a4-4" name="a4">
                    （4）	運動場館經營管理人員 
                  </label>
                </div>
              </li>
              

            </ol>

            <hr>
            
            <ol class="questionList">
              <li class="questionList-item">
                <h3>運動設施可能建置於校園、公園或運動園區(亦或是獨立場館)；管理機關為體育局(處、場)者依促參法歸屬為哪個類組之公共建設？</h3>
                <div class="answers">
                  <label for="b1-1">
                    <input type="checkbox" id="b1-1" name="b1">
                    （1）	文教設施 
                  </label>
                  <label for="b1-2">
                    <input type="checkbox" id="b1-2" name="b1">
                    （2）	休閒設施 
                  </label>
                  <label for="b1-3">
                    <input type="checkbox" id="b1-3" name="b1">
                    （3）	運動設施 
                  </label>
                  <label for="b1-4">
                    <input type="checkbox" id="b1-4" name="b1">
                    （4）	公共設施 
                  </label>
                </div>
              </li>
            </ol>
            <hr>
            
            <ol class="questionList">
              <li class="questionList-item">
                <h3>有關保護設施使用者健康與安全責任之相關法規，主要為？</h3>
                <div class="answers">
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
              </li>
              <li class="questionList-item">
                <h3>請寫出承載量推算的4種基礎？</h3>
                <div class="answers">
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
              </li>
              <li class="questionList-item">
                <h3>請列舉4種我國政府硬性要求各「國民運動中心」須配置之專業證照人才？</h3>
                <div class="answers">
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
              </li>
              <li class="questionList-item">
                <h3>請列舉5項游泳池常見的意外類型？</h3>
                <div class="answers">
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
              </li>
            </ol>

            <div class="sendAllAnswer">
              <div class="submitBtn">
                <a href="">送出</a>
              </div>
              <a href="" class="exit">提前結束測試</a>
            </div>


          </div>
          
        </div> -->

<!--------------------- 原頁面框架 ----------------->
        <div class="pageBox">
          <div v-html="Document.Content" class="test"></div>
          <div class="gallery">
            <section>
              <img
                v-for="i in Images"
                :key="i.ImageID"
                :src="i.ImageUrl | contentCDN"
                @click="showGallery($event)"
              />
            </section>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import Menu from "@/plugins/menu.js";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Document: null,
      Images: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
    this.setBase("articlePage document" + this.$route.params.id);
      try {
        await this.$api.authDocument(this.$route.params.id);
      } catch (e) {
        alert("抱歉您沒有權限查看此文章");
        window.history.go(-1);
        return;
      }

      var result = await this.$api.getDocument(this.$route.params.id);
      this.Document = result;
      this.Images = await this.$api.getImages(0, this.$route.params.id);
      var menu = Menu.MenuMap[this.Document.MenuID];

      var menus = Menu.getParents(menu).reverse();
      this.Breadcrumbs = [
        ...menus.map((x) => ({ Title: x.Title, Url: "/documents" })),
        { Title: this.Document.Title, Url: "#" },
      ];

      this.loadCustomJs();
    },
    showGallery($event) {
      var _this = $event.target;
      $(".lightbox").fadeIn(300);
      $(".lightbox").append(
        "<img src='" +
          $(_this).attr("src") +
          "' alt='" +
          $(_this).attr("alt") +
          "' />"
      );
      $(".filter").css("background-image", "url(" + $(_this).attr("src") + ")");
      /*$(".title").append("<h1>" + $(_this).attr("alt") + "</h1>");*/
      $("html").css("overflow", "hidden");
      if ($(_this).is(":last-child")) {
        $(".arrowr").css("display", "none");
        $(".arrowl").css("display", "block");
      } else if ($(_this).is(":first-child")) {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "none");
      } else {
        $(".arrowr").css("display", "block");
        $(".arrowl").css("display", "block");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
  },
};
</script>